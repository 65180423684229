/* You can add global styles to this file, and also import other style files */
@import '../node_modules/angular-calendar/css/angular-calendar.css';

// chatbot ↓
@font-face {
  font-family: 'ManulifeJHSans_Bold';
  src: url('./assets/font/ManulifeJHSans_Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'ManulifeJHSans_Demibold';
  src: url('./assets/font/ManulifeJHSans_Demibold.ttf') format('ttf');
}

@font-face {
  font-family: 'ManulifeJHSans_Light';
  src: url('./assets/font/ManulifeJHSans_Light.ttf') format('ttf');
}

@font-face {
  font-family: 'ManulifeJHSans_Regular';
  src: url('./assets/font/ManulifeJHSans_Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'ManulifeJHSerif_Italic';
  src: url('./assets/font/ManulifeJHSerif_Italic.ttf') format('ttf');
}

:root {
  --m-green: #01a759;
  --jh-blue: #0000c2;
  --navy: #292b3e;
  --grey: #ededed;
  --light-grey: #fafafa;
  --coral: #ed6454;
  --violet: #5f4584;
  --turquoise: #04c7ba;
  --gold: #f49603;
  --border: #dddee4;
  --white: #fff;
  --font-family: 'ManulifeJHSans_Bold', 'ManulifeJHSans_Demibold', 'ManulifeJHSans_Light', 'ManulifeJHSans_Regular', 'ManulifeJHSerif_Italic';
  --base-font: 0.875rem;
  --fs-10: 0.625rem;
  --fs-12: 0.75rem;
  --fs-13: 0.813rem;
  --fs-16: 1rem;
  --fs-22: 1.375rem;
  --br-9: 0.5rem;
  --br-10: 0.625rem;
  --br-12: 0.75rem;
  --br-16: 1rem;
  --br-20: 1.25rem;
}

@media (width <=61.938rem) {
  :root {
    --br-20: 0.75rem;
  }
}

@media (width <=47.938rem) {
  app-agent {
    padding: 0;
  }
}

// chatbot ↑

%pointer {
  cursor: pointer;
}

%button-border-none {
  background-color: transparent;
  border: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.div-bg-light-grey {
  background-color: var(--cds-color-light-grey);
}

.div-bg-white {
  background-color: var(--cds-color-white);
}

.div-border-top {
  border-top: 1px solid var(--cds-color-grey);
}

.div-border-top-white {
  border-top: 1px solid var(--cds-color-white);
}

.div-border-bottom {
  border-bottom: 1px solid var(--cds-color-grey);
}

.cds-table.full {
  width: 100%;
}

.green-line {
  border-bottom: 1px solid #00a758;
}

.button-border-none {
  @extend %button-border-none;
}

.button-border-none-font {
  @extend %button-border-none;

  --font-size: var(--cds-font-size-body-1);
  --font-weight: var(--cds-font-weight-demibold);

  font-size: var(--font-size);
  font-weight: var(--cds-font-weight-demibold);
  cursor: pointer;
}

.input {
  border: 0;
  outline: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 3px solid var(--cds-color-grey);
  width: 30px;
  text-align: center;
}

.input:hover {
  border-bottom-color: var(--cds-color-m-green);
}

.pointer {
  @extend %pointer;
}

.icon-button {
  @extend %pointer;

  background-color: transparent;
  border: none;
}

.font-demibold {
  font-weight: var(--cds-font-weight-demibold);
}

.cds-color-green {
  color: var(--cds-color-m-green);
}

.cds-color-navy-light3 {
  color: var(--cds-color-text-inactive);
}

.cds-color-navy-light4 {
  color: var(--cds-text-placeholder);
}

.cds-color-grey {
  color: var(--cds-color-light-4-dark-navy);
}

.font-green {
  color: var(--cds-color-m-green);
}

.font-color-light3 {
  color: var(--cds-text-label);
}

.dropdown-border-bottom {
  border-bottom: 0.5px solid var(--cds-border-input);
}

form {
  width: 100% !important;
}

.l-plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.l-pl-0 {
  padding-left: 0 !important;
}

.l-pr-0 {
  padding-right: 0 !important;
}

.l-pa-0 {
  padding: 0 !important;
}

.l-ma-0 {
  margin: 0 !important;
}

.l-mt-6 {
  margin-top: var(--cds-spacing-06) !important;
}

.text-overflow-ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Fix mat autocomplete background-color issues
.mat-autocomplete-panel.mat-autocomplete-visible {
  background-color: var(--cds-bg-item);
}

.p-line {
  border-bottom: 1px solid var(--cds-color-grey);
  margin-top: var(--cds-layout-02);
  margin-bottom: var(--cds-layout-02);
}

.l-mt-0 {
  margin-top: 0 !important;
}

.l-mr-0 {
  margin-right: 0 !important;
}

.cd-ml-10 {
  margin-left: 10px;
}

.icon-margin {
  margin-top: 2px;
}

/** common en **/
.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 32%);
}

.mat-dialog-container {
  background-color: white;
}

// Fix two scrollbar while open cdk-popup-component
html.cdk-global-scrollblock {
  overflow-y: hidden;
}

cds-cell {
  vertical-align: middle;
}

.line-break {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}

.line-no-wrap {
  white-space: nowrap;
}

.black-tip {
  background: black;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-top: 10px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.div-border {
  border: 1px solid var(--cds-color-grey);
}

.div-border-right {
  border-right: 1px solid var(--cds-color-grey);
}

.div-border-left {
  border-left: 1px solid var(--cds-color-grey);
}

.cds-text-coral {
  color: var(--cds-text-cta);
}

.cds-button:focus {
  background-color: var(--cds-bg-cta-tertiary) !important;
  color: var(--cds-text-main) !important;
}

.text-red {
  color: var(--cds-text-error);
}

.text-link {
  border-bottom: 1px solid var(--cds-bg-ml);
  cursor: pointer;

  &:hover {
    border-bottom: unset !important;
  }
}

.full-width {
  width: 100%;
}

.l-pr-6 {
  padding-right: var(--cds-spacing-06) !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-weight-6 {
  font-weight: 600 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.div-border-bottom-m-green {
  border-bottom: 2px solid var(--cds-color-m-green);
}

.italic {
  font-style: italic;
  font-weight: 400;
}

.l-mb-9 {
  margin-bottom: 3.5rem;
}

.l-mb-18 {
  margin-bottom: 18px !important;
}

.l-mr-20 {
  margin-right: 20px !important;
}

.l-mb-20 {
  margin-bottom: 20px !important;
}

.l-mt-20 {
  margin-top: 20px !important;
}

.l-pr-20 {
  padding-right: 20px !important;
}

.l-pt-56 {
  padding-top: 56px !important;
}

.l-fsr-1 {
  flex-shrink: 1;
}

.box-size-border {
  box-sizing: border-box;
}

.l-mb-0 {
  margin-bottom: 0 !important;
}

.text-underline {
  border-bottom: 2px solid var(--cds-color-m-green);
  cursor: pointer;
}

.cds-popup-container.cds-popup-full {
  width: 100%;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.l-fg-1 {
  flex-grow: 1;
}

.ngx-datatable.material {
  box-shadow: none !important;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #fff !important;
}

.btn-cds-primary {
  background: #ec6453;
  border: none;
  padding: 17px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  height: auto;
  min-width: 9.375rem;
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  display: inline-block;
  text-transform: unset;
  letter-spacing: unset;
  font-family: inherit;
}

.cds-popup-container.calendar-popup-bg {
  background-color: var(--cds-color-light-grey);
  padding-bottom: 0 !important;
}

.calendar-popup-bg .mat-dialog-container {
  background-color: var(--cds-color-light-grey);
}

.search-area-btn {
  display: flex;

  cds-button {
    margin-right: 20px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  button {
    width: 100%;
    min-width: fit-content;
  }
}

@media screen and (max-width: 1024px) {
  .desktop-md-hide {
    display: none;
  }
}

.cdk-overlay-container {
  z-index: 1070 !important;
}

.has-danger {
  color: red;
}

.required {
  &::after {
    content: '*';
    position: relative;
    font-size: inherit;
    color: red;
    padding-left: 0.25rem;
    font-weight: 700;
  }
}

.z-index-2 {
  z-index: 2 !important;
}

/* stylelint-disable selector-class-pattern */
.tabClsss cds-tab-group .cds-tab-nav-container {
  background-color: transparent !important;
  border-top: none !important;
}

.agentTab cds-tab-group .cds-tab-nav-container {
  width: 100% !important;
  margin-left: 0 !important;
}
